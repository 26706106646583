import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import useWindowOrientation from "use-window-orientation";
import React, { useRef, useState, useEffect } from "react";
import "./App.css";
import { Grid } from "@mui/material";
import markers from "./marks.json";

function App() {
	const { portrait } = useWindowOrientation();
	const [numberArray, setNumberArray] = useState([]);
	const [currentlyChecking, setCurrentlyChecking] = useState(null);
	const [loading, setLoading] = useState(true);
	const [arrayLength, setArrayLength] = useState(portrait ? 20 : 50);
	const [sorting, setSorting] = useState(false);
	const [sortingSpeed, setSortingSpeed] = useState(25);
	const [doneSorting, setDoneSorting] = useState(false);
	const paused = useRef(false);
	const barRef = useRef(0);
	const slideRef = useRef(0);

	useEffect(() => {
		console.log(barRef.current.clientHeight);
	}, [barRef, slideRef]);

	function shuffleArray(array) {
		for (var i = array.length - 1; i > 0; i--) {
			var j = Math.floor(Math.random() * (i + 1));
			var temp = array[i];
			array[i] = array[j];
			array[j] = temp;
		}
	}

	function delay(time) {
		return new Promise((resolve) => setTimeout(resolve, time));
	}

	function checkIfOrdered(number1, number2) {
		if (number1 > number2) {
			return false;
		} else {
			return true;
		}
	}

	async function sort() {
		setSorting(true);
		let arrayOfNumbers = numberArray;
		let sorted = false;
		let runs = 1;
		while (!sorted) {
			let everythingSorted = true;

			for (let i = 0; i < arrayOfNumbers.length - runs; i++) {
				if (paused.current === true) {
					setSorting(false);
					setCurrentlyChecking(null);
					paused.current = false;
					return null;
				}
				setCurrentlyChecking(i);
				if (!checkIfOrdered(arrayOfNumbers[i], arrayOfNumbers[i + 1])) {
					[arrayOfNumbers[i], arrayOfNumbers[i + 1]] = [arrayOfNumbers[i + 1], arrayOfNumbers[i]];
					everythingSorted = false;
				}
				await delay(50 - sortingSpeed);
				setNumberArray([...arrayOfNumbers]);
			}
			runs++;
			if (everythingSorted) {
				sorted = true;
				setCurrentlyChecking(null);
			}
		}

		setSorting(false);
		setDoneSorting(true);
	}

	if (loading) {
		buildArray(arrayLength);
		setLoading(false);
	}

	function buildArray(newArrayLength) {
		let newArray = [];
		for (let i = 1; i < newArrayLength; i++) {
			newArray.push(i);
		}
		shuffleArray(newArray);
		setNumberArray([...newArray]);
		setDoneSorting(false);
	}

	function onArraySizeSliderChange(e) {
		if (e.target.value !== arrayLength) {
			setArrayLength(e.target.value);
			buildArray(e.target.value);
		}
	}
	function onSortingSpeedSliderChange(e) {
		if (e.target.value !== sortingSpeed) {
			setSortingSpeed(e.target.value);
		}
	}

	function returnButton() {
		//this really needs an overhaul, what was I thinking?
		if (sorting) {
			return (
				<Button onClick={() => (paused.current = true)} variant="contained" color="error">
					Stop
				</Button>
			);
		} else if (doneSorting) {
			return (
				<Button onClick={() => buildArray(arrayLength)} variant="contained" color="success">
					Reset
				</Button>
			);
		} else {
			return (
				<Button onClick={() => sort()} variant="contained">
					Sort
				</Button>
			);
		}
	}

	return (
		<>
			<Container className="flexContainer flexColumn containerRoot">
				<h1 className="flexItem flexZero title">Bubble Sort {portrait ? returnButton() : "Visualizer"}</h1>
				<Grid ref={slideRef} className="flexItem flexZero" spacing={portrait ? 0 : 8} container>
					{!portrait && (
						<Grid xs={2} item className="flexContainer">
							{returnButton()}
						</Grid>
					)}
					<Grid className="sliderContainer" item xs={portrait ? 12 : 5}>
						<Typography id="input-slider" gutterBottom>
							Bars{portrait ? ": " + arrayLength : ""}
						</Typography>
						<Slider
							className="muiSlider"
							defaultValue={40}
							step={null}
							marks={portrait ? markers.barValues : markers.barLabels}
							min={10}
							max={portrait ? 40 : 100}
							value={arrayLength}
							onChange={onArraySizeSliderChange}
							disabled={sorting}
						/>
					</Grid>
					<Grid className="sliderContainer" item xs={portrait ? 12 : 5}>
						<Typography id="input-slider" gutterBottom>
							Sorting speed
							{portrait
								? ": " + markers.speedLabels[sortingSpeed === 0 ? 0 : sortingSpeed / 25].label
								: ""}
						</Typography>
						<Slider
							className="muiSlider"
							onChange={onSortingSpeedSliderChange}
							value={sortingSpeed}
							min={0}
							max={50}
							step={null}
							defaultValue={25}
							marks={portrait ? markers.speedValues : markers.speedLabels}
							disabled={sorting}
						/>
					</Grid>
				</Grid>
				<div ref={barRef} className="flexContainer flexItem sorterContainer">
					{numberArray.map((barElement) => {
						const heightString = ((barElement / arrayLength) * 100).toString() + "%";
						const index = numberArray.indexOf(barElement);
						const active = index + 1 === currentlyChecking || index === currentlyChecking;
						return (
							<div className="flexItem flexContainer barContainer" key={heightString}>
								<div
									className={`bar flexItem ${active ? "active" : "passive"}`}
									style={{ minHeight: heightString, height: heightString }}
								></div>
							</div>
						);
					})}
				</div>
			</Container>
		</>
	);
}

export default App;
